import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  chatPrompt: {
    position: "fixed !important",
    bottom: "80px",
    right: "10px",
    "&::after": {
      content: '""',
      width: "20px",
      height: "20px",
      position: "absolute",
      bottom: "-8px",
      right: "18px",
      background: "#fff",
      transform: "rotate(35deg)",
      borderBottom: '1px solid lightgray',
      borderRight: '2px solid lightgray',
      borderRadius: 2,
      boxShadow: '3px 3px 3px -2px rgba(0, 0, 0, 0.3)',
    }
  },
  chatPromptBubble: {
    padding: "10px",
    borderRadius: "13px",
    border: "1px solid lightgray",
    fontSize: "1.1em",
    cursor: "pointer",
  },
  closeAction: {
    position: "absolute",
    bottom: "30px",
    right: -8,
  }
});

export default useStyles;
