import React from 'react';
import useStyles from './styles/ChatPrompt.styles';
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

/**
 * React Component for the Initial chat prompt.
 * @param props Properties: closeChatPrompt, handleChatWindowToggle, chatPromptBubble.
 * @returns {JSX.Element}
 * @constructor
 */
function ChatPrompt(props) {
  const classes = useStyles();

  return (
    <div className={classes.chatPrompt}>
      <Grow in={true}>
        <div>
          <div className={classes.closeAction}>
            <IconButton aria-label="close" size="small"  onClick={props.closeChatPrompt} style={{backgroundColor: 'white', border: '1px solid lightgray'}}>
              <CloseIcon fontSize="inherit" htmlColor='gray' />
            </IconButton>
          </div>
          <Paper elevation={3} className={classes.chatPromptBubble} onClick={() => props.handleChatWindowToggle(false)}>
          👋 Chat with us
          </Paper>
        </div>
      </Grow>
    </div>
  );
}

export default ChatPrompt;