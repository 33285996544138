import React, { useEffect, useState } from "react";
import Pusher from "pusher-js";
import moment from "moment-timezone/builds/moment-timezone-with-data";
import ChatWindow from "./ChatWindow";
import ChatToggle from "./ChatToggle";
import notificationMP3 from "../notification.mp3";
import ChatPrompt from "./ChatPrompt";

// THIS WILL IDENTIFY THE CLIENT WE ARE ON SO WE DON'T SHOW DUPLICATE MESSAGES FROM PUSHER
const clientIdentifier = Date.now();

const apiURL = process.env.REACT_APP_STU_API_URL;
const pusherKey = process.env.REACT_APP_STU_PUSHER_API_KEY;
//const TEST_MODE = false;

/**
 * Play the new message sound.
 */
function newMessageAlert() {
  const audio = new Audio(notificationMP3);
  window.focus();
  audio.play().catch((err) => {
    console.log(err);
  });
}

/**
 * Make sure the local storage is accessible.
 * @returns {boolean} is local storage accessible.
 */
function storageTest() {
  let test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

let pusher = null;

function App(props) {
  const isIframe = true;
  const [chatClosed, setChatClosed] = useState(isIframe);
  const [showPrompt, setShowPrompt] = useState(false);
  const [composeMessageValue, setComposeMessageValue] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [formError, setFormError] = useState("");
  const [conversation, setConversation] = useState({});
  const [storedConversationID, setStoredConversationID] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showTypingIndicator, setShowTypingIndicator] = useState(false);
  const [smsOptInSubmitting, setSmsOptInSubmitting] = useState(false);
  const [isLoadingConversation, setIsLoadingConversation] = useState(false);
  const [typingIndicatorDisabled, setTypingIndicatorDisabled] = useState(false);
  const [clientChatSchedule, setClientChatSchedule] = useState([]);
  const [isClientAvailable, setIsClientAvailable] = useState(false);
  const [contactInfo, setClientContactInfo] = useState("");
  const [clientCountryCode, setClientCountryCode] = useState("");

  //Check for local stored token.
  useEffect(() => {
    // CHECK TO SEE IF TOKEN IS IN STORAGE
    const jwt = accessToken;
    if (jwt && storedConversationID) {
      setAccessToken(jwt);
      loadConversation(jwt);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, storedConversationID]);

  //Setup events for messages.
  useEffect(() => {
    if (window.addEventListener) {
      // FOR STANDARDS-COMPLIANT WEB BROWSERS
      window.addEventListener("message", displayMessage, false);
    } else {
      window.attachEvent("onmessage", displayMessage);
    }

    // SEND MESSAGE TO PARENT TO STORE JWT IN LOCAL STORAGE
    const msg = JSON.stringify({ action: "get_stu_jwt" });
    window.parent.postMessage(msg, "*");

    setTimeout(function () {
      handleEnableChatPrompt();
    }, 1000);
  }, []);

  useEffect(() => {
    const chatUrl = apiURL + "chatSchedule/?uid=" + props.uid;

    const fetchData = async () => {
      try {
        const response = await fetch(chatUrl);
        const json = await response.json();
        setClientChatSchedule(json.data);
        if (!isEmpty(json.data)) {
          determineChatStatus(json.data);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedConversationID]);

  function isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  /**
   * Determine if it is a week day and during work hours.
   * @param dataObj data about chat
   */
  function determineChatStatus(dataObj) {
    let dayOfWeek = moment().isoWeekday();

    let countryCode = dataObj["client_country_code"];

    setClientCountryCode(countryCode);

    console.log(dayOfWeek + " " + countryCode + " " + dataObj["is_open_now"]);

    switch (dayOfWeek) {
      case 1:
        if (dataObj["mon_chat_status"]) {
          if (dataObj["is_open_now"]) {
            setIsClientAvailable(true);
          }
        }
        break;
      case 2:
        if (dataObj["tue_chat_status"]) {
          if (dataObj["is_open_now"]) {
            setIsClientAvailable(true);
          }
        }
        break;
      case 3:
        if (dataObj["wed_chat_status"]) {
          if (dataObj["is_open_now"]) {
            setIsClientAvailable(true);
          }
        }
        break;
      case 4:
        if (dataObj["thu_chat_status"]) {
          if (dataObj["is_open_now"]) {
            setIsClientAvailable(true);
          }
        }
        break;
      case 5:
        if (dataObj["fri_chat_status"]) {
          if (dataObj["is_open_now"]) {
            setIsClientAvailable(true);
          }
        }
        break;
      case 6:
        if (dataObj["sat_chat_status"]) {
          if (dataObj["is_open_now"]) {
            setIsClientAvailable(true);
          }
        }
        break;
      case 7:
        if (dataObj["sun_chat_status"]) {
          if (dataObj["is_open_now"]) {
            setIsClientAvailable(true);
          }
        }
        break;
      default:
        setIsClientAvailable(false);
    }
  }

  /**
   * Update the JWT access token and load the conversation again.
   */
  function reloadConversation() {
    const jwt = accessToken;
    if (jwt) {
      setAccessToken(jwt);
      loadConversation(jwt);
    }
  }

  // eslint-disable-next-line no-unused-vars
  function getLocalStorage(key) {
    return localStorage.getItem(key);
  }

  /**
   * Display message
   * @param e
   */
  function displayMessage(e) {
    let key = e.message ? "message" : "data";
    let data = e[key];

    try {
      data = JSON.parse(data);
    } catch (e) {
      return;
    }

    if (data.action === "stu_jwt") {
      let messageData = {};
      if (data.data && data.data.conversationID) {
        messageData = data.data;
      } else {
        messageData = JSON.parse(data.data);
      }
      setStoredConversationID(messageData.conversationID);
      setAccessToken(messageData.token);
    }
  }

  // eslint-disable-next-line no-unused-vars
  function setLocalStorage(key, value, action) {
    const msg = JSON.stringify({ action: action, key: key, value: value });
    window.parent.postMessage(msg, "*");
  }

  function onStartChatFormSubmit(values) {

    handleInvalidToken();
    setIsSubmitting(true);
    const requestData = {
      uid: props.uid,
      email: values.validationEmail,
      phone: values.validationPhone,
      name: values.name,
      captchaToken: values.captchaToken,
      clientIdentifier: clientIdentifier,
    };
    fetch(apiURL + "initializeConversation/", {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => Promise.all([response, response.json()]))
      .then(([response, json]) => {
        if (response.status < 200 || response.status >= 300) {
          let error = new Error(json.message);
          error.response = response;
          throw error;
        }
        // console.log(json.data);
        setMessages([]);
        setIsSubmitting(false);
        setConversation(json.data.conversation);
        setAccessToken(json.data.token);
        setClientContactInfo(values.email);

        // SEND MESSAGE TO PARENT TO STORE JWT IN LOCAL STORAGE
        const msg = JSON.stringify({
          action: "set_stu_jwt",
          data: {
            token: json.data.token,
            conversationID: json.data.conversation.id,
          },
        });
        window.parent.postMessage(msg, "*");

        //loadConversation(json.data.token, json.data.conversationID)
      })
      .catch(function (ex) {
        setIsSubmitting(false);
        setFormError(ex.message);
      });
  }

  function onStartChatFormSubmitClientUnavailable(values) {
    console.log("OnStartCFSCU");
    handleInvalidToken();
    setIsSubmitting(true);
    const requestData = {
      uid: props.uid,
      name: values.name,
      captchaToken: values.captchaToken,
      clientIdentifier: clientIdentifier,
    };
    fetch(apiURL + "initializeConversationClientUnavailable/", {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => Promise.all([response, response.json()]))
      .then(([response, json]) => {
        if (response.status < 200 || response.status >= 300) {
          let error = new Error(json.message);
          error.response = response;
          throw error;
        }
        // console.log(json.data);
        setMessages([]);
        setIsSubmitting(false);
        setConversation(json.data.conversation);
        setAccessToken(json.data.token);

        // SEND MESSAGE TO PARENT TO STORE JWT IN LOCAL STORAGE
        const msg = JSON.stringify({
          action: "set_stu_jwt",
          data: {
            token: json.data.token,
            conversationID: json.data.conversation.id,
          },
        });
        window.parent.postMessage(msg, "*");

        //loadConversation(json.data.token, json.data.conversationID)
      })
      .catch(function (ex) {
        setIsSubmitting(false);
        setFormError(ex.message);
      });
  }

  /**
   * Opt in for chat notifications with SMS
   * @param contactMethod Method requested for contact (sms, call, or email)
   * @param value Contact destination for person.
   * @param message Message to send.
   */
  function smsOptIn(contactMethod, value, message) {
    // contactMethod should be (sms, call, or email)
    if (!value) {
      value = "";
    }
    if (!message) {
      message = "";
    }
    setSmsOptInSubmitting(true);
    const requestData = {
      conversationID: conversation.id,
      value: value,
      contactMethod: contactMethod,
      message: message,
    };
    fetch(apiURL + "smsOptIn/", {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => Promise.all([response, response.json()]))
      .then(([response, json]) => {
        if (response.status < 200 || response.status >= 300) {
          let error = new Error(json.message);
          error.response = response;
          throw error;
        }

        setSmsOptInSubmitting(false);
        setConversation(json.data.conversation);
      })
      .catch(function (ex) {
        console.log(ex);
        setSmsOptInSubmitting(false);
      });
  }

  /**
   * End conversation if currently in one.
   */
  function setConversationToClosed() {
    if (!conversation.id) {
      return;
    }
    const requestData = { conversationID: conversation.id };
    fetch(apiURL + "setConversationToClosed/", {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => Promise.all([response, response.json()]))
      .then(([response, json]) => {
        if (response.status < 200 || response.status >= 300) {
          let error = new Error(json.message);
          error.response = response;
          throw error;
        }
        setConversation(json.data.conversation);
      })
      .catch(function (ex) {});
  }

  /**
   * Automatic response
   */
  function clientUnavailableCloseConversation() {
    if (!conversation.id) {
      return;
    }
    const requestData = { conversationID: conversation.id };
    fetch(apiURL + "clientUnavailableCloseConversation/", {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => Promise.all([response, response.json()]))
      .then(([response, json]) => {
        if (response.status < 200 || response.status >= 300) {
          let error = new Error(json.message);
          error.response = response;
          throw error;
        }
        setConversation(json.data.conversation);
      })
      .catch(function (ex) {});
  }

  /**
   * Send message to the chat Dashboard
   * @param text - Message to be sent.
   * @param tempID
   */
  function sendMessageToSoTellUs(text, tempID) {
    const requestData = {
      clientIdentifier: clientIdentifier,
      conversationID: conversation.id,
      body: text,
    };
    fetch(apiURL + "messages/", {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => Promise.all([response, response.json()]))
      .then(([response, json]) => {
        if (response.status < 200 || response.status >= 300) {
          let error = new Error(json.message);
          error.response = response;
          throw error;
        }
      })
      .catch(function (ex) {
        let updatedMessages = [];

        setMessages((state) => {
          updatedMessages = [...state];
          return state;
        });
        const foundIndex = updatedMessages.findIndex((x) => x.id === tempID);
        updatedMessages[foundIndex].error = true;
        setMessages(updatedMessages);
      });
  }

  /**
   * Clear local storage, conversations, and access tokens.
   * @returns {null}
   */
  // IF TOKEN IS INVALID CLEAR LOCAL STORAGE & REFRESH WINDOW
  function handleInvalidToken() {
    if (storageTest() === true) {
      // AVAILABLE
      localStorage.clear();
      setStoredConversationID(null);
      setAccessToken("");
    } else {
      return null;
    }
  }

  /**
   * Load a conversation based on the conversationId
   * @param jwt
   * @param conversationID
   * @returns {boolean}
   */
  function loadConversation(jwt, conversationID) {
    conversationID = storedConversationID;
    if (conversationID) {
      // eslint-disable-next-line no-self-assign
      conversationID = conversationID;
    }

    if (!conversationID) {
      return false;
    }

    setIsLoadingConversation(true);
    fetch(apiURL + "messages/?conversationID=" + conversationID, {
      method: "GET",
      withCredentials: true,
      headers: {
        Authorization: "Bearer " + jwt,
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        setIsLoadingConversation(false);
        setConversation(response.data.conversation);
        if (response.data.messages.length === 1) {
          setShowTypingIndicator(true);
          setTimeout(() => {
            // WAITING
            newMessageAlert();
            setChatClosed(false);
            setShowTypingIndicator(false);
            setMessages((messages) => [...messages, ...response.data.messages]);
          }, 3000);
        } else {
          setMessages((messages) => [...messages, ...response.data.messages]);
        }
        // SET UP PUSHER
        Pusher.logToConsole = true;
        pusher = new Pusher(pusherKey, {
          cluster: "us3",
          authEndpoint: apiURL + "pusherAuthentication/",
          auth: {
            headers: {
              Authorization: "Bearer " + jwt,
            },
          },
        });

        console.log("A " + JSON.stringify(pusher))

        console.log("PUSHED " + JSON.stringify(response.data.conversation))

        let channel = pusher.subscribe(response.data.conversation.channel_name);
        channel.bind("new-message", function (data) {
          if (data.clientIdentifier === clientIdentifier) {
            return false;
          }

          if (data.automated_message === 1) {
            setShowTypingIndicator(true);
            setTimeout(() => {
              // WAITING
              newMessageAlert();
              setChatClosed(false);
              setShowTypingIndicator(false);
              setMessages((messages) => [
                ...messages,
                {
                  id: Date.now(),
                  sent_by_contact: data.sent_by_contact,
                  body: data.body,
                  sent: data.sent,
                },
              ]);
            }, 3000);
            return;
          }

          newMessageAlert();
          setChatClosed(false);
          setShowTypingIndicator(false);
          setMessages((messages) => [
            ...messages,
            {
              id: Date.now(),
              sent_by_contact: data.sent_by_contact,
              body: data.body,
              sent: data.sent,
            },
          ]);
        });

        channel.bind("conversation-accepted", function (data) {
          setConversation(data);
          setChatClosed(false);
        });

        channel.bind("conversation-closed", function (data) {
          setConversation(data.conversation);
        });

        channel.bind("client-typing", function (data) {
          setChatClosed(false);
          setShowTypingIndicator(true);
          setTimeout(() => {
            setShowTypingIndicator(false);
          }, 4000);
        });
      })
      .catch((err) => {
        handleInvalidToken();
      });
  }

  function closeChatPrompt() {
    handleDisableChatPrompt();
  }

  /**
   * Send message and status because the client has minimized or unfocused the chat
   */
  function handleDisableChatPrompt() {
    setShowPrompt(false);
    const msg = JSON.stringify({ action: "minimize" });
    localStorage.setItem("stu_chat_prompt_disabled", "1");

    window.parent.postMessage(msg, "*");
  }

  /**
   * Send message and status that the client has re-opened the chat.
   * @returns {null}
   */
  function handleEnableChatPrompt() {
    if (storageTest() === true) {
      // AVAILABLE
      const promptDisabled = localStorage.getItem("stu_chat_prompt_disabled");
      if (promptDisabled) {
        return;
      }
    } else {
      // UNAVAILABLE
      return null;
    }

    // IGNORE IF CHAT WINDOW IS OPEN
    let updatedState;
    setChatClosed((currentState) => {
      //DO NOT CHANGE THE STATE BY GET THE UPDATED STATE
      updatedState = currentState;
      return currentState;
    });
    if (updatedState === false) {
      return;
    }
    const msg = JSON.stringify({ action: "show_chat_prompt" });
    window.parent.postMessage(msg, "*");
    setTimeout(()=>{
      setShowPrompt(true);
    }, (1000* 20) )
  }

  /**
   * Send typing status indicator while the client is typing.
   * @param e
   */
  function handleComposeMessageChange(e) {
    setComposeMessageValue(e.target.value);
    if (typingIndicatorDisabled === false) {
      const channel = pusher.subscribe(conversation.channel_name);
      channel.trigger("client-typing", { id: conversation.id });

      setTypingIndicatorDisabled(true);
      setTimeout(() => {
        setTypingIndicatorDisabled(false);
      }, 4000);
    }
  }

  /**
   * Send messages to STU with the ID message and timestamp.
   */
  function handleNewMessage() {
    if (composeMessageValue.length) {
      const tempID = Date.now();
      setMessages([
        ...messages,
        {
          id: tempID,
          sent_by_contact: true,
          body: composeMessageValue,
          sent: moment().unix(),
        },
      ]);
      setComposeMessageValue("");
      sendMessageToSoTellUs(composeMessageValue, tempID);
    }
  }

  /**
   * Handling of Chat widget toggling.
   * @param closeChat
   */
  function handleChatWindowToggle(closeChat) {
    setChatClosed(closeChat);
    handleDisableChatPrompt();
  }

  /**
   * If the widget is not in an iFrame.
   */
  if (!isIframe) {
    return <p style={{ textAlign: "center" }}>Not authorized.</p>;
  }

  /**
   * Return the Chat widget.
   */
  if(props.widgetConfig.enabled) {
    return (
        <div>
          {showPrompt && (
              <ChatPrompt
                  closeChatPrompt={closeChatPrompt}
                  handleChatWindowToggle={handleChatWindowToggle}
              />
          )}
          <ChatWindow
              handleNewMessage={handleNewMessage}
              messages={messages}
              composeMessageValue={composeMessageValue}
              handleComposeMessageChange={handleComposeMessageChange}
              handleChatWindowToggle={handleChatWindowToggle}
              chatClosed={chatClosed}
              isIframe={isIframe}
              accessToken={accessToken}
              onStartChatFormSubmit={onStartChatFormSubmit}
              onStartChatFormSubmitClientUnavailable={
                onStartChatFormSubmitClientUnavailable
              }
              isSubmitting={isSubmitting}
              isLoadingConversation={isLoadingConversation}
              formError={formError}
              conversation={conversation}
              reloadConversation={reloadConversation}
              smsOptIn={smsOptIn}
              smsOptInSubmitting={smsOptInSubmitting}
              setConversationToClosed={setConversationToClosed}
              clientUnavailableCloseConversation={clientUnavailableCloseConversation}
              handleInvalidToken={handleInvalidToken}
              showTypingIndicator={showTypingIndicator}
              widgetConfig={props.widgetConfig}
              clientChatSchedule={clientChatSchedule}
              isClientAvailable={isClientAvailable}
              clientCountryCode={clientCountryCode}
              clientContactInfo={contactInfo}
          />
          {isIframe ? (
              <ChatToggle
                  handleChatWindowToggle={handleChatWindowToggle}
                  chatClosed={chatClosed}
              />
          ) : null}
        </div>
    );
  }else{
    return null;
  }
}

export default App;
